import { ReactNode } from 'react';

export interface IUsersProviderProps {
    children: ReactNode;
}

export interface IUser {
    id: number;
    fullname: string;
    phone: string;
    role: string;
}
export interface IBonus {
    bonuses: number;
}
export interface IBonusActivities {
    id: number;
    name: string;
    required_bonuses: string;
    bonus_points: number;
}

export interface IUsersState {
    user: IUser | null;
    loading: boolean;
    isAuth: boolean;
    token: string;
}

export interface IUserContextType extends IUsersState {
    setUser: () => Promise<void>;
    deleteCurrentUser: () => Promise<void>;
    setToken: (token: string) => void;
}

export interface IAction {
    type: string;
    payload?: IUser | null | boolean | string;
}

export const LOADING = 'LOADING';
export const IS_AUTH = 'IS_AUTH';
export const SET_USER = 'SET_USER';
export const DELETE_CURRENT_USER = 'DELETE_CURRENT_USER';
export const SET_TOKEN = 'SET_TOKEN';
